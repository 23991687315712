import classNames from "classnames"
import Image from "next/image"
import React from "react"

import type { Nullable } from "@bounce/util"
import { Button } from "@bounce/web-components"

import type { UseVariantOptions } from "./useVariant"
import { useVariant } from "./useVariant"
import { useSectionViewed } from "../../../../hooks/useSectionViewed"

import ladyPhoneSm from "./assets/lady-phone-sm.png"
import ladyPhone from "./assets/lady-phone.webp"

export type AppBannerSecondaryProps = {
  titleClassName?: string
  titleAs?: "h2" | "h3"
  variant?: "app-download" | "find-closest-location" | "book-online"
  className?: string
  searchQuery?: Nullable<string>
  name?: string
} & UseVariantOptions

export const AppBannerSecondary = ({
  variant,
  titleClassName,
  titleAs: TitleAs = "h2",
  searchQuery,
  name,
  className,
}: AppBannerSecondaryProps) => {
  const { title, description, ctaHref, ctaOnClick, ctaLabel } = useVariant({ variant, from: name, searchQuery })
  const { sectionRef } = useSectionViewed({ name: "AppBannerLuggageStorageSecondary" })

  return (
    <section ref={sectionRef} className={classNames(className, "w-full bg-primary-700")}>
      <div className="relative z-0 mx-auto flex min-h-[390px] w-full max-w-screen-xl flex-col overflow-hidden md:min-h-[600px] md:flex-row xl:min-h-[690px]">
        <div className="mt-16 flex w-full max-w-[590px] flex-col px-6 md:mt-32 md:pr-10">
          <TitleAs className={classNames(titleClassName, "title-display text-white")}>{title}</TitleAs>
          <p className="paragraph1 mt-4 max-w-[410px] !leading-[140%] text-white md:mt-5 xl:max-w-none">
            {description}
          </p>
          <Button href={ctaHref} onClick={ctaOnClick} rel="nofollow" color="white" className="my-7 md:mt-10 xl:mt-12">
            {ctaLabel}
          </Button>
        </div>
        <div className="relative -z-1 w-full sm:absolute sm:inset-y-0 sm:left-[410px]">
          <Image
            src={ladyPhone}
            alt=""
            loading="lazy"
            className="relative hidden sm:absolute sm:inset-y-0 sm:left-0 sm:inline sm:h-full sm:w-auto"
            quality={90}
          />
          <Image src={ladyPhoneSm} alt="" loading="lazy" className="w-full sm:hidden" quality={90} />
        </div>
      </div>
    </section>
  )
}
