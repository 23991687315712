import { useCallback, useMemo } from "react"

import Datadog from "@bounce/datadog"
import { EUROPEAN_COUNTRY_CODES, useLocale } from "@bounce/i18n"
import { useGeoIp } from "@bounce/util"

import { ConfigValue, useMultiVariantFlag } from "../config/flags"

const DEFAULT_MULTIPLIERS = { bag_standard: 1.0, bag_compact: 0.7 }

const useBackpacksPricingExperiment = (overridedCountry?: string) => {
  const locale = useLocale()
  const { data: geoIp } = useGeoIp()
  const country = overridedCountry ?? geoIp?.country ?? locale.countryCode

  const [pricingMultipliers] = useMultiVariantFlag(
    ConfigValue.BackpacksPricingProductMultipliersV2,
    '{"bag_standard": 1.0, "bag_compact": 0.7}',
  )

  const parsedMultipliers: { [key: string]: number } = useMemo(() => {
    try {
      return !EUROPEAN_COUNTRY_CODES.includes(country) ? JSON.parse(pricingMultipliers) : DEFAULT_MULTIPLIERS
    } catch (error) {
      void Datadog.addError("Failed to parse multipliers from ConfigCat", { error })
      return DEFAULT_MULTIPLIERS
    }
  }, [pricingMultipliers, country])

  const getDiscountedLowestPrice = useCallback(
    (price: number | null) => {
      if (price === null) return null

      const multiplier = parsedMultipliers.bag_compact ?? 1.0

      // We need to take into account what the original multiplier of backpacks is - 0.7 at the time of the experiment
      return multiplier === DEFAULT_MULTIPLIERS.bag_compact
        ? price
        : Math.round(((price / DEFAULT_MULTIPLIERS.bag_compact) * multiplier) / 10) * 10
    },
    [parsedMultipliers],
  )

  return { getDiscountedLowestPrice }
}

export default useBackpacksPricingExperiment
