import type { ReactNode } from "react"
import React from "react"

import { useTranslation } from "@bounce/i18n"
import { PNImage, useIsMobile } from "@bounce/web-components"

import { AppAggregateRating } from "../../../../components/LuggageStorage/AppAggregateRating/AppAggregateRating"

import heroImage from "@/assets/img/default_hero_4.webp"

export type HeroContainerProps = {
  children: ReactNode
}

export const HeroContainer = ({ children }: HeroContainerProps) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation("marketing-ls-home")

  return (
    <header className="relative mx-auto flex w-full max-w-screen-2xl flex-col">
      {isMobile && <AppAggregateRating className="px-6" />}
      <PNImage
        src={heroImage}
        width={640}
        height={764}
        priority
        alt={t(
          "screen.homeNextScreen.hero.imageAlt",
          "A smiling young woman looks down at her phone in front of a Bounce location. A yellow backpack and orange suitcase are next to her, which will be kept safe while she explores.",
        )}
        loading="eager"
        data-chromatic="ignore"
        className="right-0 top-0 mr-0 h-auto overflow-hidden object-cover md:absolute md:w-[270px] md:pt-10 lg:flex lg:w-[500px] xl:w-[600px]"
      />
      <div className="relative z-[1] mx-auto flex h-full w-full max-w-screen-xl flex-1 flex-col px-6 pb-12 md:items-start md:pt-8 xl:pb-40">
        <div className="md:mt-16 md:w-2/3 lg:w-2/4 xl:w-1/2">
          <div className="flex h-full w-full max-w-full flex-col pr-0 md:pr-2.5">{children}</div>
        </div>
      </div>
    </header>
  )
}
