import React from "react"

import { Trans, useTranslation } from "@bounce/i18n"
import type { Nullable } from "@bounce/util"

import { useAppStoreUrl } from "../../../../hooks/useAppStoreUrl"
import { useUrl } from "../../../../libs/router"
import { goToApp, useAppURL } from "../../../../utils/appURL"

export type UseVariantOptions = {
  searchQuery?: Nullable<string>
  from?: string
  variant?: "app-download" | "find-closest-location" | "book-online"
}

const useLocationsUrl = ({ searchQuery }: Pick<UseVariantOptions, "searchQuery">) => {
  const buildAppURL = useAppURL()
  const { getUrl } = useUrl()
  const href = buildAppURL(getUrl({ path: "/s" }), { query: searchQuery })

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()
    const href = ev.currentTarget.href

    goToApp(href, { cta: "HowItWorksSecondaryNextLuggageStorage" })
  }

  return { href, onClick }
}

export const useVariant = ({ variant, from, searchQuery }: UseVariantOptions) => {
  const { t } = useTranslation()
  const appStoreUrl = useAppStoreUrl({
    app: "luggageStorage",
    eventProps: { location: "AppBannerLuggageStorageSecondary", cta: from },
  })
  const locationsUrl = useLocationsUrl({ searchQuery })

  if (!variant || variant === "app-download") {
    return {
      title: (
        <Trans
          i18nKey="cmp.appBannerLuggageStorageSecondary.titleV5"
          defaults="Get the <br />Bounce app"
          components={{
            br: <br />,
          }}
        />
      ),
      description: t(
        "cmp.appBannerLuggageStorageSecondary.description",
        "Instantly find locations nearby to drop off your luggage wherever you go.",
      ),
      ctaHref: appStoreUrl.href,
      ctaOnClick: appStoreUrl.onClick,
      ctaLabel: t("cmp.appBannerLuggageStorageSecondary.cta", "Download"),
    }
  }

  return {
    title: t("cmp.appBannerLuggageStorageSecondaryV1.findLocationsTitle", "Book in 3 taps"),
    description: t(
      "cmp.appBannerLuggageStorageSecondary.findLocationsDescription",
      "Instantly find locations nearby to drop off your luggage wherever you go.",
    ),
    ctaLabel: t("cmp.appBannerLuggageStorageSecondary.findLocationsCta", "Find closest locations"),
    ctaHref: locationsUrl.href,
    ctaOnClick: locationsUrl.onClick,
  }
}
