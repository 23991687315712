import React from "react"

import { Trans, useTranslation } from "@bounce/i18n"
import { useIsMobile } from "@bounce/web-components"

import { HeroContainer } from "./HeroContainer"
import { FeatureFlag, useFeatureFlag } from "../../../../config/flags"
import { AppAggregateRating } from "@/components/LuggageStorage/AppAggregateRating/AppAggregateRating"
import { Marquee } from "@/components/LuggageStorage/Marquee/Marquee"
import { SearchBar } from "@/components/LuggageStorage/SearchBar/SearchBar"
import type { GenericInterpolations } from "@/hooks/useGenericInterpolations"
import type { AggregateRating } from "@/libs/bounce/types"

type HeroProps = {
  interpolations: GenericInterpolations
  aggregateRating: AggregateRating
}

export const Hero = ({ interpolations, aggregateRating }: HeroProps) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation("marketing-ls-home")
  const [isNewHomepageHeadlineEnabled] = useFeatureFlag(FeatureFlag.NewHomepageHeadline)

  const headline = isNewHomepageHeadlineEnabled ? (
    <Trans
      ns="marketing-ls-home"
      i18nKey="screen.homeNextScreen.hero.titleV6"
      defaults="Check in your bags,<br />check out the city"
      components={{
        br: <br />,
      }}
    />
  ) : (
    t("screen.homeNextScreen.hero.titleV4", "Luggage storage near you")
  )

  const subtitle = isNewHomepageHeadlineEnabled
    ? t("screen.homeNextScreen.hero.subtitleV9", {
        defaultValue: "Luggage storage from {{price}} / day",
        price: interpolations.PRICE,
      })
    : t("screen.homeNextScreen.hero.subtitleV8", {
        defaultValue: "Freedom to explore from {{price}} / day",
        price: interpolations.PRICE,
      })

  return (
    <>
      <HeroContainer>
        {!isMobile && <AppAggregateRating />}
        <h1 className="title1 mb-2 break-words text-black md:mb-6">{headline}</h1>
        <p className="text-base text-black md:text-xl xl:text-2xl">{subtitle}</p>
        <SearchBar ctaName="SearchBarCTAHome" showPickers className="mt-6 lg:mt-[72px]" />
      </HeroContainer>
      <Marquee price={interpolations.PRICE} aggregateRating={aggregateRating} className="mb-24" />
    </>
  )
}
