import { useMemo } from "react"

import { formatStripeAmount, useBounceGlobalValues, useLocale } from "@bounce/i18n"

import useBackpacksPricingExperiment from "./useBackpacksPricingExperiment"
import { useEnrichedGeoIp } from "./useEnrichedGeoIp"
import { FeatureFlag, useFeatureFlag } from "../config/flags"
import type { Country } from "../libs/bounce/bounceSdk.types"

type UseGenericInterpolationsOptions = {
  countries?: Country[]
}

export type GenericInterpolations = {
  PRICE: string
  LOWEST_PRICE: string
  INSURANCE_AMOUNT: string
  COUNTRY_CODE: string
  NUM_LOCATIONS: string
  NUM_CITIES: string
  NUM_BAGS_STORED: string
}
/**
 * Returns an object with generic interpolated values based on the user's location and bounce countries.
 */
export const useGenericInterpolations = (props: UseGenericInterpolationsOptions): GenericInterpolations => {
  const countries = props.countries || []
  const locale = useLocale()
  const { country, countryDetails, luggagePricePerDay, lowestPriceCents } = useEnrichedGeoIp({
    countries,
  })
  const bounceGlobals = useBounceGlobalValues()
  const [backpacksEnabled] = useFeatureFlag(FeatureFlag.Backpacks)
  const { getDiscountedLowestPrice } = useBackpacksPricingExperiment(country)
  const discountedLowestPrice = getDiscountedLowestPrice(lowestPriceCents)
  const lowestPricePerDay =
    countryDetails && discountedLowestPrice
      ? formatStripeAmount(discountedLowestPrice, countryDetails.currency, locale.language)
      : bounceGlobals.lowestPricePerDay

  const interpolations: GenericInterpolations = useMemo(() => {
    const { bounceShieldProtection, numLocations, numCities, numBagsStored } = bounceGlobals

    return {
      COUNTRY_CODE: country,
      PRICE: backpacksEnabled ? lowestPricePerDay : luggagePricePerDay,
      LOWEST_PRICE: lowestPricePerDay,
      INSURANCE_AMOUNT: bounceShieldProtection,
      NUM_LOCATIONS: numLocations,
      NUM_CITIES: numCities,
      NUM_BAGS_STORED: numBagsStored,
    }
  }, [bounceGlobals, country, luggagePricePerDay, lowestPricePerDay, backpacksEnabled])

  return interpolations
}
