import { useEffect, useMemo, useState } from "react"

import { formatStripeAmount, useLocale } from "@bounce/i18n"

import { FeatureFlag, useFeatureFlag } from "../../../config/flags"
import useBackpacksPricingExperiment from "../../../hooks/useBackpacksPricingExperiment"
import type { Country } from "../../../libs/bounce/bounceSdk.types"

export type UsePricingSelectorProps = {
  defaultCountry: string
  countries: Country[]
}

export type UsePricingSelectorOption = {
  countryCode: string
  countryName: string
  formattedPrice: string
}

/**
 * Returns a selector for pricing information based on countries.
 * @param countries - An array of objects containing country, luggagePrice and currency information.
 * @returns An object containing the selected option, options array and onChange function.
 */

const getLowestPrice = (withDiscount: number | null, withoutDiscount: number) => withDiscount || withoutDiscount

export const usePricingSelector = (props: UsePricingSelectorProps) => {
  const { countries } = props
  const { language, countryCode } = useLocale()
  const defaultCountry = props.defaultCountry || countryCode
  const [backpacksEnabled, isReady] = useFeatureFlag(FeatureFlag.Backpacks)
  const { getDiscountedLowestPrice } = useBackpacksPricingExperiment(defaultCountry)

  // Generate options array based on countries, the country name is generated by the Intl API.
  const options: UsePricingSelectorOption[] = useMemo(() => {
    const regionNames = new Intl.DisplayNames([language], { type: "region" })
    const options = countries

      .map(({ country, lowestPrice, luggagePrice, currency }) => ({
        countryCode: country,
        countryName: regionNames.of(country) || country,
        formattedPrice: formatStripeAmount(
          isReady && backpacksEnabled
            ? getLowestPrice(getDiscountedLowestPrice(lowestPrice), lowestPrice)
            : luggagePrice,
          currency,
          language,
        ),
      }))
      .sort((a, b) => a.countryName.localeCompare(b.countryName))

    return options
  }, [countries, language, backpacksEnabled, isReady, getDiscountedLowestPrice])

  const defaultOption = useMemo(
    () => options.find(option => option.countryCode === defaultCountry) || options[0],
    [options, defaultCountry],
  )
  const [selectedOption, setSelectedOption] = useState(defaultOption)

  // Reset the selected option when the default country changes.
  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  const onChange = (value: string) => {
    const option = options.find(option => option.countryCode === value)
    if (!option) return

    setSelectedOption(option)
  }

  return {
    selectedOption,
    options,
    onChange,
  }
}
